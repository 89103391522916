import Vue from 'vue'
import VueI18n from 'vue-i18n'
import csv from './i18n-file.csv'

Vue.use(VueI18n)

function csvToJson(csv) {
    const lines = csv;
    const headers = lines[0];
    const result = {
        "ko": {},
        "th": {},
        "zh": {},
        "jp": {},
        "en": {

        },"??": {
            
        }
    };
    for (let i = 1; i < lines.length; i++) {
        const currentline = lines[i];

        for (let j = 0; j < headers.length; j++) {
            result[headers[j]][currentline[0]] = currentline[j];
        }
    }
    return result;

}



// Use the result as the messages for VueI18n
export const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: csvToJson(csv),
});
