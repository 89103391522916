<template>
    <nav class="navigation-menu">
        <ul>
            <li @click="logout"><h5>Logout</h5></li>
            <li @click="setPasscode"><h5>{{ isPasscodeSet ? "Change PIN" : "Set PIN" }}</h5></li>
            <li @click="$store.commit('setNavigationMenuVisible', false)"><h5>Close</h5></li>
        </ul>
    </nav>
</template>
<script>
export default {
    name: 'NavigationMenu',
    computed: {
        isPasscodeSet() {
            return localStorage.getItem("__passcode") !== null;
        },
    },
    methods: {
        logout() {
            this.$store.dispatch('resetStoredStates');
            this.$router.replace('/signin');
            this.$store.commit('setNavigationMenuVisible', false);
        },
        setPasscode() {
            if(this.isPasscodeSet) {
                this.$router.replace({
                    name: 'passcode',
                    params: { mode: 'update', redirectTo: '/' }
                });
            } else {
                this.$router.replace({
                    name: 'passcode',
                    params: { mode: 'register', redirectTo: '/' }
                });
            }
            this.$store.commit('setNavigationMenuVisible', false);
        }
    }
}
</script>
<style lang="scss" scoped>
nav.navigation-menu {
    position: fixed;
    left: 0; top: 0;
    width: 100vw; height: 100vh;
    backdrop-filter: blur(4px);
    background: rgba(black, 0.5);

    padding: 2em;
    box-sizing: border-box;

    ul {
        list-style: none;
        margin: 0; padding: 0;

        li {
            font-size: 24px;
            font-weight: 300;
            margin-bottom: 1em;
        }

        li:last-child {
            margin-top: 2em;
        }
    }
}
</style>
