<template>
    <footer class="navbar">
        <!--
        <router-link to="/home">
            <img :src="require('../assets/icon-nav1.svg')"/>
        </router-link>
        -->
        <router-link to="/home">
            <svg xmlns="http://www.w3.org/2000/svg" width="90" height="59" viewBox="0 0 90 59">
                <g id="그룹_107" data-name="그룹 107" transform="translate(-105)">
                    <path id="패스_47" data-name="패스 47" d="M23.879,8.215V4.858A3.358,3.358,0,0,0,20.521,1.5H4.972a3.471,3.471,0,1,0,0,6.942h18.9A3.358,3.358,0,0,1,27.229,11.8V24.713a3.358,3.358,0,0,1-3.358,3.358H4.859A3.358,3.358,0,0,1,1.5,24.713V4.972" transform="translate(136 14)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
            </svg>
        </router-link>
		<router-link to="/swap">
            <svg xmlns="http://www.w3.org/2000/svg" width="90" height="59" viewBox="0 0 90 59">
                <g id="그룹_109" data-name="그룹 109" transform="translate(-195)">
                    <g id="그룹_105" data-name="그룹 105" transform="translate(115.649 -37.915)">
                    <ellipse id="타원_11" data-name="타원 11" cx="6.793" cy="6.793" rx="6.793" ry="6.793" transform="translate(109.351 52.915)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <rect id="사각형_31" data-name="사각형 31" width="13.884" height="13.884" rx="3" transform="translate(125.39 68.116)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path id="패스_45" data-name="패스 45" d="M34.133,11.918,30.015,7.8l4.118-4.118" transform="translate(98.487 50.698)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <line id="선_12" data-name="선 12" x2="10.21" transform="translate(128.502 58.498)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path id="패스_46" data-name="패스 46" d="M11.878,28.339,16,32.457l-4.118,4.118" transform="translate(104.443 43.157)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <line id="선_13" data-name="선 13" x1="10.21" transform="translate(110.229 75.614)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    </g>
                </g>
                </svg>

        </router-link>
        <router-link to="/stake">
            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 24 24">
            <path  stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M17 8H5m12 0a1 1 0 0 1 1 1v2.6M17 8l-4-4M5 8a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.6M5 8l4-4 4 4m6 4h-4a2 2 0 1 0 0 4h4a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1Z"/>
            </svg>

        </router-link>
        <router-link to="/profile">
            <svg xmlns="http://www.w3.org/2000/svg" width="90" height="59" viewBox="0 0 90 59">
                <g id="그룹_110" data-name="그룹 110" transform="translate(-285)">
                    <g id="그룹_106" data-name="그룹 106" transform="translate(102.257 -38.258)">
                    <path id="패스_48" data-name="패스 48" d="M17.086,14.068a1.53,1.53,0,1,1-1.53-1.53,1.53,1.53,0,0,1,1.53,1.53" transform="translate(208.13 49.133)" fill="#acacac"/>
                    <path id="패스_49" data-name="패스 49" d="M28.332,14.068a1.53,1.53,0,1,1-1.53-1.53,1.53,1.53,0,0,1,1.53,1.53" transform="translate(204.436 49.133)" fill="#acacac"/>
                    <path id="패스_50" data-name="패스 50" d="M13.384,21.689a7.936,7.936,0,0,0,11.23,0" transform="translate(208.34 46.128)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <ellipse id="타원_12" data-name="타원 12" cx="13.565" cy="13.565" rx="13.565" ry="13.565" transform="translate(213.743 54.258)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    </g>
                </g>
            </svg>
        </router-link>
    </footer>
</template>

<script>
export default {
    name: 'FooterNavbar',

    methods: {
        popup() {
            alert(this.$i18n.t("coming soon!"))
        }
    },
}
</script>

<style lang="scss" scoped>
@import '../const';

footer.navbar {
    position: fixed;
    left: 0; bottom: 0;
    width: 100%; height: 60px;

    background: $BASE_BACK_COLOR;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);

    display: flex;

    & > * {
        flex: 1 0;
        display: flex;
        align-items: center;
        justify-content: center;

        // opacity: 0.5;

        transition: opacity 0.3s;
        stroke: #acacac;
        &.router-link-exact-active {
            opacity: 1.0;
            stroke: #ffdd00;
        }

        img {
            max-width: 60px;
            max-height: 60px;
        }
    }

}
</style>
