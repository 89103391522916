<template>
    <header class="app-bar">
        <img id="go-back" @click="$router.go(-1)" :src="require('../assets/arrow-left-solid.svg')" v-if="canNavigateBack"/>
        <h1 v-if="title"><slot name="customizedTitle">{{ $t(title) }}</slot></h1>
        <div v-else style="display: flex; justify-content: space-between; align-items: center; margin: 0 0; width: 100%;">
            <img :src="require('../assets/logo.png')" @click="$router.push('/')"
                style="width:auto; height: 36px; max-width: 160px;"/>
            <div style="display: flex;">
                    <div style="flex-direction: column; padding: 4px;">
                        <div style="font-size: 14px;">
                            {{$t('GMF Wallet')}}
                        </div>
                    </div>
            </div>
           
            <select id="country" name="country" class="form-control"
                style="width: 100px; height: 30px; margin-right: 10px;"

                @change="onCountryChange">
                <option value="en">🇺🇸 English</option>
                <option value="ko">🇰🇷 한국어</option>
                <option value="jp">🇯🇵 日本語</option>
                <option value="th">🇹🇭 ภาษาไทย</option>
                <option value="zh">🇨🇳 漢字</option>
            </select>
        </div>
    </header>
</template>

<script>
import httpClient, { API_HOST } from "../httpClient";

export default {
    data() {
        return {
            user24h: null,
            userFull: null,

        }
    },
    async mounted() {
            //this.get24Huser();
            //this.getFullUser();
            const country = document.cookie
                .split('; ')
                .find(row => row.startsWith('country='))
                ?.split('=')[1];

            if (country) {
                this.$i18n.locale = country;
                this.$store.commit('setLanguage', country);
            }
            this.onCountryChange = this.onCountryChange.bind(this);

            document.getElementById('country').value = country || 'en';
            
    },
    props: {
        title: { required: false, type: String },
        canNavigateBack: { default: false, type: Boolean },
        isNavigationDisabled: { default: false, type: Boolean }
    },
    methods : {
        async get24Huser() {
            try {
                const { data } = await httpClient.get(API_HOST(`/api/system/user-24h-count`));
                this.user24h = data.count;
            } catch (e) {
                console.error(e)
                this.user24h = 0;
            }

        },
        async getFullUser() {
            try {
                const { data } = await httpClient.get(API_HOST(`/api/system/user-count`));
                this.userFull = data.count;
            } catch (e) {
                this.userFull = 0;
            }
        },
        async onCountryChange(event) {
            const selectedCountry = event.target.value;
            this.$i18n.locale = selectedCountry;
            this.$store.commit('setLanguage', selectedCountry);
            document.cookie = `country=${selectedCountry}`;
        }
    }
}
</script>
