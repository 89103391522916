<template>
    <div class="home has-footer-navbar">
        <Appbar />

        <div class="circle-container" style="border-color: aliceblue; color: white;">
            <VueSvgGauge
                :start-angle="-110"
                :end-angle="110"
                :value="TotalStaked == 0 ? 0 : TotalClaimed / TotalStaked * 100"
                :separator-step="75"
                :scale-interval="10"
                :inner-radius="80"
                >
            </VueSvgGauge>
            <div class="inner-text">
                <span> {{$t("Round")}} {{ ($store.state.profile && $store.state.profile.Stake_Round ? $store.state.profile.Stake_Round : "1") || "1" }} : {{ TotalClaimed }} / {{ TotalStaked }} XUSD ({{(TotalStaked == 0 ? 0 : TotalClaimed / TotalStaked * 100).toFixed(2)}} %)</span>
                <br/>
                <span v-show="TotalStaked == 0 ? false : TotalClaimed / TotalStaked * 100 > 75 " style="color: red;"><b>{{ $t('WARN : RE-STAKE is Needed')}}</b></span>
            </div>
        </div>

        <div class="cryptocurrency-list">
            <router-link class="cryptocurrency-item" v-for="(currency, currencyId) in $store.state.currencies" :key="currencyId" :to="`/${currencyId}/balance`">
                <div class="cryptocurrency">
                    <div class="info-container" v-show="currencyId.toLowerCase() != 'btc'">
                        <div style="padding:8px">
                            <div class="currency-area">
                                <div class="currency-name">{{ currency.displayName }}</div>
                                <div class="currency-id">{{ currencyId.toLocaleUpperCase() }}</div>
                            </div>
                            <!--
                            <div class="currency-balance" v-show="currency.symbol !== 'BTC'">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" style="width:12px; height:12px">
                                    <path d="M18 1.5c2.9 0 5.25 2.35 5.25 5.25v3.75a.75.75 0 01-1.5 0V6.75a3.75 3.75 0 10-7.5 0v3a3 3 0 013 3v6.75a3 3 0 01-3 3H3.75a3 3 0 01-3-3v-6.75a3 3 0 013-3h9v-3c0-2.9 2.35-5.25 5.25-5.25z" />
                                </svg>
                                Unlocked : {{ balanceAmountForDisplay(currency.balance - currency.locked, currency.fractionDigits) }} {{ currencyId.toLocaleUpperCase() }}
                            </div>
                            -->
                            <div class="currency-balance">
                                {{
                                    currencyId.toLowerCase() == 'btc' ? (Math.floor(parseFloat(currency.balance) * (10 ** 2)) / (10 ** 2))?.toFixed(currency.fractionDigits) :
                                    (currencyId.toLowerCase() == 'eth' ? (Math.floor(parseFloat(currency.balance) * (10 ** 6)) / (10 ** 6))?.toFixed(currency.fractionDigits) :
                                    (Math.floor(parseFloat(currency.balance) * (10 ** 6)) / (10 ** 6))?.toFixed(currency.fractionDigits))
                                }} {{ currencyId.toLocaleUpperCase() }}
                            </div>
                        </div>
                        <div>
                            <aside><img :src="currency.icon" /></aside>
                        </div>
                    </div>
                </div>
            </router-link>

            <section class="transaction-list">
                <h2 style="text-align: center; color: #ffdd00; padding-bottom: 4px">
                    {{$t('XUSD Transaction History')}}
                </h2>
                <table width="100%">
                    <colgroup>
                        <col width="20%" />
                        <col width="20%" />
                        <col width="30%" />
                        <col width="30%" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{{$t('Block')}}</th>
                            <th>{{$t('Txid')}}</th>
                            <th align="left">{{$t('Timestamp')}}</th>
                            <th align="left">{{$t('Amount')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            style="opacity: 1"
                            v-for="transaction in transactions"
                            :key="transaction.hash"
                            @click="openStxExplorer(transaction.hash)"
                        >
                            <td style="opacity: 0.6">
                                {{ transaction.block_height || `${$t('pending...')}` }}
                            </td>
                            <td style="opacity: 0.6" align="left">
                                {{
                                    transaction.hash.slice(0, 5) +
                                    '...' +
                                    transaction.hash.slice(-5)
                                }}
                            </td>
                            <td style="opacity: 0.6" align="left">
                                {{ new Date(transaction.time * 1000).toISOString() }}
                            </td>
                            <td style="opacity: 0.6" align="left">
                                {{ transaction.out[0]?.value / 10 ** 8 }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <FooterNavbar />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Appbar from '../components/Appbar';
import FooterNavbar from '../components/FooterNavbar';
import { toFixedNumberAndLocaleString } from '../components/utils';
import Axios from 'axios';
import httpClient, { API_HOST } from "../httpClient";
import { VueSvgGauge } from 'vue-svg-gauge'

export default {
    components: {
        FooterNavbar,
        Appbar,
        VueSvgGauge,
    },
    data() {
        return {
            dataFecther: null,
            transactions: [],
            datacollection: {},
            interval: '1h',
            duration: '24h',
            message: null,
            confirm_result: 'N',

            options: {
                animation: {
                    duration: 1, // disable animation
                },
                legend: {
                    //hides the legend
                    display: false,
                },
                scales: {
                    //hides the y axis
                    yAxes: [
                        {
                            display: false,
                            ticks: {
                                fontSize: 15,
                                maxTicksLimit: 1,
                                fontColor: 'white',
                                callback: function (value) {
                                    return '$' + toFixedNumberAndLocaleString(value, 2);
                                },
                            },
                            gridLines: {
                                color: 'rgb(95,169,85)',
                            },
                        },
                    ],
                    xAxes: [
                        {
                            display: false,
                        },
                    ],
                },
            },
            graphRange: {
                min: null,
                max: null,
            },
            DonationFr: 0,
            DonationDollar: 0,
            TotalStaked: 0,
            TotalClaimed: 0,
        }
    },
    computed: {
        ...mapGetters(['profile']),
        ...mapGetters(['currencies', 'rates']),
        totalConvertedBalance() {
            return toFixedNumberAndLocaleString(
                Object.keys(this.currencies).reduce((prevVal, key) => {
                    // console.log(key)
                    if (key !== 'eth') {
                        return prevVal + this.currencies[key].balance;
                    } else {
                        return prevVal;
                    }
                }, 0),
                2
            );
        },
        totalBalanceFontSize() {
            let total_length = this.totalConvertedBalance.length;
            let font_size = 40;

            if (total_length >= 6) {
                while ((font_size / 2) * total_length > 140) {
                    font_size = font_size - font_size / (2 * total_length);
                }
            }

            return font_size;
        },
    },
    async mounted() {
        try {
            const { data : stakingList } = await httpClient.get(API_HOST('/__authorize/stake'));
            console.log(stakingList)
            const list = stakingList.data;
            //let total_claimable = 0.0;
            //let total_claimed = 0.0;
            //for(const staked of list) {
            //    total_claimable += staked.maxClaimable
            //    total_claimed += staked.claimCount
            //}
            //console.log("HELP", total_claimable, total_claimed)

            if(list && list[0]) {
                this.TotalStaked = list[0].maxClaimable;
                this.TotalClaimed = list[0].claimCount;
                if(this.TotalStaked < this.TotalClaimed) {
                    this.TotalStaked = this.TotalClaimed;
                }
            } else {
                this.TotalStaked = 0.0;
                this.TotalClaimed = 0.0;
            }
        } catch (e) {
            console.log(e)
        }

        this.getDonationUSD();
        this.fetchTxHistory();
        this.dataFecther = setInterval(this.fetchTxHistory, 3000);
        this.$store.dispatch('refreshDashboard');
        await this.$store.dispatch('refreshRate');
        this.filldata();
    },
    unmounted() {
        clearInterval(this.dataFecther);
    },
    methods: {
        async fetchTxHistory() {
            const { data } = await Axios.get(
                'https://blockchain.info/unconfirmed-transactions?format=json'
            );
            this.transactions = data?.txs?.slice(0, 10);
        },
        balanceAmountForDisplay(balance, fractionDigits) {
            return toFixedNumberAndLocaleString(balance, fractionDigits);
        },
        balancePriceForDisplay(balance, currencyId) {
            return toFixedNumberAndLocaleString(balance * this.rates[`${currencyId}`], 2);
        },
        async filldata() {
            for (const currency of ['btc', 'eth', 'fr', 'pzbtc']) {
                try {
                    const { data } = await httpClient.get(
                        API_HOST(`/api/system/rate/${currency}/${this.interval}`)
                    );

                    let minVal = 99999999;
                    let maxVal = -99999999;
                    data.map((val) => {
                        val = Number(val);
                        if (val < minVal) {
                            minVal = val;
                        }
                        if (val > maxVal) {
                            maxVal = val;
                        }
                    });

                    this.graphRange[currency] = {
                        min: minVal,
                        max: maxVal,
                    };

                    // value changes not reflected on LineChart.js
                    // this.options.scales.yAxes[0].ticks.min = minVal;
                    // this.options.scales.yAxes[0].ticks.max = maxVal;

                    this.datacollection[currency] = {
                        labels: [...new Array(data.length)],
                        datasets: [
                            {
                                type: 'line',
                                lineTension: 0.3,
                                backgroundColor: (ctx) => {
                                    const canvas = ctx.chart.ctx;
                                    const gradient = canvas.createLinearGradient(0, 0, 0, 80);
                                    gradient.addColorStop(0, 'rgba(95,169,85,0.6)');
                                    gradient.addColorStop(1, 'rgba(95,169,85,0.0)');
                                    return gradient;
                                },
                                borderColor: 'rgba(95,169,85,0.4)',
                                data: data,
                                pointRadius: 0,
                            },
                        ],
                    };
                } catch (e) {
                    this.datacollection[currency] = null;
                }
            }
        },
        async getDonationUSD() {
            try {
                const { data } = await httpClient.get(API_HOST(`/api/system/donation-usd`));
                this.DonationFr = data.DonationFr;
                this.DonationDollar = data.DonationDollar;
            } catch (e) {
                this.amount = 0;
            }
        },
        openBtcExplorer(txid) {
            window.open(
                `https://www.blockchain.com/explorer/transactions/btc/${txid}`,
                '_self ',
                'location=yes'
            );
        },
        openStxExplorer(txid) {
            window.open(`https://explorer.hiro.so/txid/${txid}`, '_self ', 'location=yes');
        },
        openEthExplorer(txid) {
            window.open(`https://etherscan.io/tx/${txid}`, '_self ', 'location=yes');
        }
    },
};
</script>

<style lang="scss" src="./Home.scss" />
