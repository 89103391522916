import { mapGetters } from 'vuex';

const CurrencyPage = {
    computed: {
        ...mapGetters(['currencies']),
        currentCurrencyId: {
            get() {
                return this.$route.params['currency'];
            }
        },
        currentCurrency: {
            get() {
                return this.currencies[this.currentCurrencyId];
            }
        },
        isLocked: {
            get() {
                return this.$route.params['locked'] == 'locked'
            }
        }
    }
};

export default CurrencyPage;
