<template>
    
        <div class="template">
            <Appbar title="My Profile" />
            <div class="section-wrapper" v-if="profile">
                <section>
                    <aside class="icon">
                        <img :src="require('../assets/icon-email.svg')" />
                    </aside>
                    <div>
                        <h3>{{ $t('Email') }}</h3>
                        <p>{{ profile.email }}</p>
                    </div>
                </section>
                <!--
            <section>
                <aside class="icon">
                    <img :src="require('../assets/icon-profile.svg')" />
                </aside>
                <div>
                    <h3>Name</h3>
                    <p>{{ profile.name }}</p>
                </div>
            </section>
            <section>
                <aside class="icon">
                    <img :src="require('../assets/icon-phone.svg')" />
                </aside>
                <div>
                    <h3>Phone</h3>
                    <p>{{ profile.phone }}</p>
                </div>
            </section>
            -->
            <section>
                <aside class="icon">
                    <img :src="require('../assets/icon-ping.svg')" />
                </aside>
                <div>
                    <h3>{{$t(`My Address`)}}</h3>
                    <p>{{ profile.eth.address }}</p>
                </div>
            </section>
            <section>
                <aside class="icon">
                    <img :src="require('../assets/icon-profile.svg')" />
                </aside>
                <div>
                    <h3>{{$t("Referral code")}}
                    </h3>
                    <p>{{ profile.referral_code }}</p>
                </div>
                <div style="margin-left:12px">
                    <button
                            style="flex: 1; font-size: 14px"
                            class="button secondary"
                            @click="copyLink"
                        >
                        {{ $t('Copy Link') }}
                    </button>
                </div>
            </section>
            <section>
                <aside class="icon">
                    <img :src="require('../assets/icon-profile.svg')" />
                </aside>
                <div>
                    <h3>{{$t("Referee")}}</h3>
                    <p>{{ profile?.referee + profile?.referee_support }} ({{ profile?.referee_support }})</p>
                </div>
            </section>
			<!-- 유저 레벨 비활성화 (삭제 예정)
            <section>
                <aside class="icon">
                    <img :src="require('../assets/icon-profile.svg')" />
                </aside>
                <div>
                    <h3>Level</h3>
                    <p>{{ profile?.level?.includes("VIP1") ? '★' : (
                        profile?.level?.includes("VIP2") ? '☆' :
                        'User'
                    ) }}</p>
                </div>
            </section>
			-->
                <div class="container" style="display: flex; padding: 0 0 24px">
                    <button
                        style="flex: 1; margin-right: 16px; font-size: 14px"
                        class="button positive"
                        @click="logout"
                    >
                        {{ $t('Logout') }}
                    </button>
                    <!-- <button style="flex: 1; margin-left: 8px;"  class="button" @click="exportPK">Export PK and delete account</button> -->

                    <router-link to="/editprofile" style="flex: 1">
                        <button style="font-size: 14px" class="button positive">
                            {{ $t('Edit Profile') }}
                        </button>
                    </router-link>
                </div>
            </div>
            <FooterNavbar />
        </div>
    
</template>

<script>
import { mapGetters } from 'vuex';

import FooterNavbar from '../components/FooterNavbar';
import Appbar from '../components/Appbar';
import httpClient, { API_HOST } from '../httpClient';

export default {
    components: { Appbar, FooterNavbar },
    computed: {
        ...mapGetters(['profile']),
        isPasscodeSet() {
            return localStorage.getItem('__passcode') !== null;
        },
    },
    methods: {
        logout() {
            this.$store.dispatch('resetStoredStates');
        },
        copyLink() {
            /*
            window.cordova.plugins.clipboard.copy(this.currentCurrency.address);
            window.plugins.toast.show(
                'Address copied', // message
                'short', // toast length
                'bottom', // position
                function (a) {
                    // toast success callback
                    console.log('toast success: ' + a);
                },
                function (b) {
                    // toast error callback
                    alert(`${this.$t('toast error: ')}` + b);
                }
            );
            */
            // http://localhost:8080/#/signup
            navigator.clipboard.writeText(window.location.host + "/#/signup?referral=" + this.profile.referral_code);
            alert(`${this.$t('Link copied!')}`);
        },
        async exportPK() {
            let result = await window.confirm(
                `${this.$t(
                    'Do you want to export PK and delete account? You cannot reuse your email once you deactivate account.'
                )}`
            );

            if (result === true) {
                await httpClient.get(API_HOST(`/__authorize/exportpk`));
                alert(`${this.$t('PK sent to email. Check email please')}`);
                this.logout();
            }
        },
        setPasscode() {
            if (this.isPasscodeSet) {
                this.$router.replace({
                    name: 'passcode',
                    params: { mode: 'update', redirectTo: '/' },
                });
            } else {
                this.$router.replace({
                    name: 'passcode',
                    params: { mode: 'register', redirectTo: '/' },
                });
            }
            this.$store.commit('setNavigationMenuVisible', false);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../const';

div.section-wrapper {
    padding: 30px 16px;
    margin-bottom: 2em;
}

section {
    padding: 25px;
    margin-bottom: 20px;

    display: flex;
    background: $BASE_COLOR;
    border: 1px solid $BORDER_COLOR;

    aside.icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 24px;
        width: 20px;
    }

    h3 {
        font-weight: normal;
        color: rgba($TEXT_COLOR, 0.6);
        font-size: 14px;
    }

    p {
        padding: 0;
        margin: 0;

        word-break: break-all;
    }
}
</style>
