const toFixedNumberConverter = function (amount, fractionDigits, isLocaleString) {

    // check amount and fractionDigits are Number
    if (!amount || Number.isNaN(amount)) {
        amount = 0;
    }
    if (!fractionDigits || Number.isNaN(fractionDigits)) {
        fractionDigits = 0;
    } else {
        // apply floor to make integer;
        fractionDigits = Math.floor(fractionDigits);
    }

    // do the thing
    let noPrecision = Math.floor(amount);
    let fractionNumbers = '';

    if (fractionDigits >= 1) {
        let onlyPrecision = amount - noPrecision;
        fractionNumbers = (Math.floor(onlyPrecision * 10 ** fractionDigits) / 10 ** fractionDigits)?.toFixed(fractionDigits).substr(1);
    }

    if (isLocaleString) {
        return noPrecision.toLocaleString('en-us') + fractionNumbers;
    } else {
        return noPrecision.toString(10) + fractionNumbers;
    }

};

export const toFixedNumberAndLocaleString = function (amount, fractionDigits) {
    return toFixedNumberConverter(amount, fractionDigits, true);
};

export const toFixedNumber = function (amount, fractionDigits) {
    return toFixedNumberConverter(amount, fractionDigits, false);
};
