<template>
    <div id="app">
        <template v-if="$route.meta.singleview">
            <router-view id="router-view-single"></router-view>
        </template>
        <template v-else-if="isApplicationReady">
            <router-view id="router-view" v-if="isApplicationReady" />
            <NavigationMenu v-if="isNavigationMenuVisible" />
            <div id="qr-scan-area" v-if="isQRScanning">
                <div class="guide-area">
                    Scan QR Code<br />
                    <div @click="stopScan">x</div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NavigationMenu from './components/NavigationMenu';

export default {
    components: { NavigationMenu },
    computed: {
        ...mapGetters(['isLoggedIn', 'isNavigationMenuVisible', 'isQRScanning', 'isApplicationReady']),
        computedViewportWidth: {
            get: function() {
                return this.viewportWidth;
            },
            set: function(newVal) {

                let scrollLeft = 0;
                let diff = 0;

                diff = newVal - this.viewportWidth;

                if (diff !== 0) {

                    if (document.documentElement.scrollLeft) {
                        scrollLeft = document.documentElement.scrollLeft;
                    }

                    if (document.body.scrollLeft) {
                        scrollLeft = document.body.scrollLeft;
                    }

                    let calculated = scrollLeft - diff;
                    if(calculated < 0) {
                        calculated = 0;
                    }

                    document.body.scrollLeft = document.documentElement.scrollLeft = calculated;

                    //console.log(`newViewportWidth - newVal:${newVal} / scrollLeft:${scrollLeft} / diff:${diff} / calculated:${calculated}`);

                }


                this.viewportWidth = newVal;
            },
        },
        computedViewportHeight: {
            get: function() {
                return this.viewportHeight;
            },
            set: function(newVal) {

                let scrollTop = 0;
                let diff = 0;

                diff = newVal - this.viewportHeight;

                if (document.documentElement.scrollTop) {
                    scrollTop = document.documentElement.scrollTop;
                }

                if (document.body.scrollTop) {
                    scrollTop = document.body.scrollTop;
                }

                let calculated = scrollTop - diff;
                if(calculated < 0) {
                    calculated = 0;
                }

                document.body.scrollTop = document.documentElement.scrollTop = calculated;

                //console.log(`newViewportHeight - newVal:${newVal} / scrollTop:${scrollTop} / diff:${diff} / calculated:${calculated}`);

                this.viewportHeight = newVal;
            },
        },
    },

    data: function() {
        return {
            viewportWidth: 0,
            viewportHeight: 0,
        };
    },

    watch: {
        isLoggedIn: {
            handler: function (newVal) {
                if (newVal !== true) {
                    this.$router.replace('/signin');
                } else {
                    if (this.$route.path !== '/') {
                        this.$router.replace('/');
                    }
                }
            }
        },
        isQRScanning: {
            handler: function (newVal) {
                if (newVal) {
                    window.document.querySelector('html').classList.add('qr-scan');
                } else {
                    window.document.querySelector('html').classList.remove('qr-scan');
                }
            }
        },
        isApplicationReady: {
            handler: async function (newVal) {
                if (newVal !== true) {
                    if(!this.isApplicationReady && !this.isLoggedIn && this.$route.path !== '/splash') {
                        this.$router.replace('/splash');
                        await this.sleep(1000);
                    }
                    this.$store.dispatch('ping');
                } else if (newVal === true) {
                    // only when isApplicationReady is true.

                    this.onDeviceReady();


                    // if isApplicationReady is true and if localStorage has __passcode
                    if (localStorage.getItem("__passcode")) {
                        // load passcode input Screen
                        this.$router.replace({
                            name: 'passcode',
                            params: { mode: 'input', redirectTo: '/' }
                        });
                    }
                }
            },
            immediate:true,
        },
    },

    mounted() {

        // initial window size:
        this.handleViewportResize();
        window.addEventListener('resize', this.handleViewportResize);


        // backbutton, beforeunload 이벤트가 안돼서 router를 조작했습니다.
        this.$router.beforeEach((to, from, next) => {
            let cancelEvent = false;
            let redirectToSignin = false;
            let scrollToTop = true;

            if (this.isQRScanning) {
                // QR 스캔중인경우 라우팅을 불허합니다.
                this.stopScan();
                cancelEvent = true;
            }

            if (to.meta && (to.meta.needsLogin === true) && !this.isLoggedIn) {
                // 로그인이 필요한 곳에 로그인 안 한 채 진입했습니다.
                redirectToSignin = true;
            }

            if (cancelEvent === true) {
                // 라우팅이 불허되었습니다.
                next(false);

                scrollToTop = false;
            } else if (redirectToSignin === true) {
                // 로그인 부터 해야합니다.
                next('/signin');
            } else {
                // 라우팅이 허가되었습니다.
                next();
            }

            if (scrollToTop) {
                // scroll to top
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            }
        });
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleViewportResize);
    },

    methods: {
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve,ms));
        },
        async stopScan() {
            this.$store.commit('setQRScanning', false);
        },
        onDeviceReady() {
            // on device ready;
            if (Object.hasOwnProperty.call(window, 'cordova')) {
                // cordova is ready
                if (window.cordova.InAppBrowser) {
                    // apply cordova.InAppBrowser.pen to global window.open.
                    window.open = window.cordova.InAppBrowser.open;
                }
            }
        },
        handleViewportResize() {
            //console.log('handleViewportResize');
            this.computedViewportWidth = document.documentElement.clientWidth;
            this.computedViewportHeight = document.documentElement.clientHeight;
        },
    }
}
</script>

<style lang="scss" src="./App.scss" />
