<template>
    
        <div class="page">
            <appbar is-navigation-disabled />
            <div class="contentArea">
                <div class="logoArea">
                    <img class="logoImg" :src="require('../assets/logo.png')" />
                    <span>{{ $t('Sign Up') }}</span>
                </div>

                <div class="input-entity">
                    <label>{{ $t('Email') }}</label>
                    <input
                        class="black"
                        type="email"
                        v-model="email"
                        :placeholder="`${$t('Enter your Email')}`"
                    />
                </div>

                <!--
            <div class="input-entity">
                <label>Name</label>
                <input class="black" v-model="name" placeholder="Enter your name" />
            </div>

            <div class="input-entity">
                <label>Telephone</label>
                <input class="black" type="tel" v-model="phone" placeholder="82+ 10-XXXX-XXXX" />
            </div>
            -->

                <div class="input-entity bottom-area watchable-password">
                    <label>{{ $t('Password') }}</label>
                    <div class="input-area">
                        <input
                            :type="passwordInputType"
                            class="input-password"
                            v-model="password"
                            :placeholder="`${$t('Enter your password')}`"
                        />
                        <button
                            class="button open-input-password"
                            @click.prevent="passwordIsOpen = !passwordIsOpen"
                        >
                            <img
                                width="auto"
                                v-if="passwordIsOpen"
                                src="../assets/input/ico_visibility-on.svg"
                            />
                            <img width="auto" v-else src="../assets/input/ico_visibility-off.svg" />
                        </button>
                    </div>
                </div>

                <div class="input-entity bottom-area watchable-password">
                    <label>{{ $t('Password Retype') }}</label>
                    <div class="input-area">
                        <input
                            :type="passwordReInputType"
                            class="input-password"
                            v-model="password_re"
                            :placeholder="`${$t('Enter your password again')}`"
                        />
                        <button
                            class="button open-input-password"
                            @click.prevent="passwordReIsOpen = !passwordReIsOpen"
                        >
                            <img
                                width="auto"
                                v-if="passwordReIsOpen"
                                src="../assets/input/ico_visibility-on.svg"
                            />
                            <img width="auto" v-else src="../assets/input/ico_visibility-off.svg" />
                        </button>
                    </div>
                </div>

                <div class="input-entity">
                    <label>{{ $t('Step Refferal') }}</label>
                    <input
                        v-model="referrer"
                        class="black"
                        :placeholder="`${$t('Type your referrer code')}`"
                        :disabled="isFixedReferrer"
                    />
                </div>

                <div class="input-entity">
                    <label>Support Refferal</label>
                    <input
                        v-model="referrerSupport"
                        class="black"
                        :placeholder="`${$t('Type your referrer code')}`"
                    />
                </div>

                <div class="button-area">
                    <button class="button positive font-size-positive" @click="performSignUp">
                        {{ $t('Sign up') }}
                    </button>
                </div>
            </div>
        </div>
    
</template>

<script>
import httpClient, { API_HOST } from '../httpClient';
import Appbar from '../components/Appbar';

export default {
    components: { Appbar },
    data() {
        return {
            email: '',
            password: '',
            password_re: '',
            name: '',
            phone: '',
            passwordIsOpen: false,
            passwordReIsOpen: false,
            referrer: '',
            referrerSupport: '',
            isFixedReferrer: false,
        };
    },
    mounted() {
        const ref = this.$route.query.referral
        if(ref) {
            this.referrer = this.$route.query.referral;
            this.referrerSupport = this.$route.query.referral;
            this.isFixedReferrer = true;
        }
    },

    computed: {
        passwordInputType: {
            get: function () {
                return this.getTypeOfInputForPassword(this.passwordIsOpen);
            },
        },

        passwordReInputType: {
            get: function () {
                return this.getTypeOfInputForPassword(this.passwordReIsOpen);
            },
        },
    },

    methods: {
        async performSignUp() {
            this.email = this.email.trim();
            if (!this.email) {
                alert(`${this.$t('check your email')}`);
                return false;
            }

            if (
                !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.exec(
                    this.email
                )
            ) {
                alert(`${this.$t('email address seems not correct')}`);
                return false;
            }

            if (!this.password || !this.password_re || this.password !== this.password_re) {
                alert(`${this.$t('check your password!')}`);
                return false;
            }

            if (!this.referrer) {
                alert(`${this.$t('referrer cannot be empty!')}`);
                return false;
            }

            const { data } = await httpClient.post(API_HOST('/__authorize/check-refferer'), {
                referrer: this.referrer,
            });

            console.log(data);
            if (data.result == false) {
                alert(`${this.$t('step referrer does not exist!')}`);
                return false;
            }

            const { data: data2 } = await httpClient.post(API_HOST('/__authorize/check-refferer'), {
                referrer: this.referrerSupport,
            });
            console.log(data2);
            if (data.result == false) {
                alert(`${this.$t('support referrer does not exist!')}`);
                return false;
            }

            const { email, password, phone, name, referrer, referrerSupport } = this;
            await this.$store.dispatch('register', {
                email,
                password,
                phone,
                name,
                referrer,
                referrerSupport,
            });
            this.$router.replace('/signin');
        },

        getTypeOfInputForPassword(isOpen) {
            if (isOpen) {
                return 'text';
            } else {
                return 'password';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.page {
    .contentArea {
        display: flex;
        flex-direction: column;

        .logoArea {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 50px 0 30px;

            .logoImg {
                width: 98px;
                height: 98px;
            }
        }

        span {
            margin-top: 20px;
            font-size: 24px;
            text-align: center;
            font-weight: bold;
        }
    }

    .button-area {
        padding: 0 16px;
        margin-top: 10px;
    }
}
</style>
