<template>
    
        <div class="page">
            <appbar is-navigation-disabled />
            <div class="contentArea">
                <div class="logoArea">
                    <img class="logoImg" :src="require('../assets/logo.png')" />
                    <span>{{ $t('Log in') }}</span>
                </div>

                <div class="input-entity input-email">
                    <label>{{ $t('Email') }}</label>
                    <input type="email" v-model="email" :placeholder="`${$t('Enter your Email')}`" />
                </div>

                <div class="input-entity bottom-area watchable-password">
                    <label>{{$t('Password')}}</label>
                    <div class="input-area">
                        <input
                            :type="passwordInputType"
                            class="input-password"
                            v-model="password"
                            :placeholder="`${$t('Enter your password')}`"
                        />
                        <button
                            class="button open-input-password"
                            @click.prevent="passwordIsOpen = !passwordIsOpen"
                        >
                            <img
                                width="auto"
                                v-if="passwordIsOpen"
                                src="../assets/input/ico_visibility-on.svg"
                            />
                            <img width="auto" v-else src="../assets/input/ico_visibility-off.svg" />
                        </button>
                    </div>
                </div>

                <div class="button-area">
                    <button class="button positive font-size-positive" @click="performSignIn">
                        {{$t('Log in')}}
                    </button>
                    <span class="password-find" @click="$router.replace('/signup')">{{$t('Sign Up')}}</span>
                </div>
            </div>
        </div>
    
</template>

<script>
import Appbar from '../components/Appbar';

export default {
    components: { Appbar },
    data() {
        return {
            email: '',
            password: '',
            passwordIsOpen: false,
        };
    },

    computed: {
        passwordInputType: {
            get: function () {
                return this.getTypeOfInputForPassword(this.passwordIsOpen);
            },
        },
    },

    methods: {
        async performSignIn() {
            const { email, password } = this;
            await this.$store.dispatch('login', { email, password });
            this.$router.replace('/');
        },

        getTypeOfInputForPassword(isOpen) {
            if (isOpen) {
                return 'text';
            } else {
                return 'password';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.page {
    .contentArea {
        display: flex;
        flex-direction: column;

        .logoArea {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 50px 0 30px;

            .logoImg {
                width: 98px;
                height: 98px;
            }

            span {
                margin-top: 20px;
                font-size: 24px;
                text-align: center;
                font-weight: bold;
            }
        }

        .button-area {
            padding: 0 16px;
            margin-top: 10px;
            display: flex;
            align-items: center;
            flex-direction: column;

            .password-find {
                margin-top: 30px;
                font-size: 14px;
                text-align: center;
                cursor: pointer;
            }
        }
    }
}
</style>
