<template>
    
        <div class="balance">
            <Appbar can-navigate-back :title="`${currentCurrency.displayName}`">
                <template v-slot:customizedTitle>
                    <b>{{ currentCurrency.symbol }}</b> {{ currentCurrency.displayName }}
                </template>
            </Appbar>
            <div class="cryptocurrency-list" v-if="currentCurrency && currentCurrencyId">
                <div class="cryptocurrency">
                    <aside>
                        <img :src="currentCurrency.icon" />
                    </aside>
                    <div>
                        <div class="currency-name">{{ currentCurrency.displayName }}</div>
                        <div class="currency-id">{{ currentCurrencyId.toLocaleUpperCase() }}</div>
                        <div class="currency-balance" v-if="rates[`${currentCurrencyId}`] > 0">
                            {{ currentCurrency.displayName }}
                        </div>
                        <br />
                        <div>{{ $t('Total :') }}</div>
                        <div v-show="currentCurrencyId.toLowerCase() == 'xusd'">
                            {{ $t('Lock :') }}
                        </div>
                        <div v-show="currentCurrencyId.toLowerCase() == 'xusd'">
                            {{ $t('Unlock :') }}
                        </div>
                    </div>
                    <div class="graph-container">
                        <div>{{ balanceAmountForDisplay }}</div>
                        <div v-show="currentCurrencyId.toLowerCase() == 'xusd'">
                            {{
                                (Math.floor(parseFloat(lockedAmount) * 10 ** 6) / 10 ** 6)?.toFixed(
                                    6
                                )
                            }}
                        </div>
                        <div v-show="currentCurrencyId.toLowerCase() == 'xusd'">
                            {{
                                (
                                    Math.floor(parseFloat(unlockedAmount) * 10 ** 6) /
                                    10 ** 6
                                )?.toFixed(6)
                            }}
                        </div>
                    </div>
                    <div class="graph-container">
                        <div>{{ currentCurrencyId.toLocaleUpperCase() }}</div>
                        <div v-show="currentCurrencyId.toLowerCase() == 'xusd'">
                            {{ currentCurrencyId.toLocaleUpperCase() }}
                        </div>
                        <div v-show="currentCurrencyId.toLowerCase() == 'xusd'">
                            {{ currentCurrencyId.toLocaleUpperCase() }}
                        </div>
                    </div>
                    <br />
                </div>
            </div>

            <div style="text-align: left; font-size: 14px" class="balance-padding-lr">
                <table>
                    <!--
                <tr>
                    <td>
                        <h3 v-if="currentCurrency.isLockable">Unlocked Amount</h3>
                    </td>
                    <td>
                        <h3 v-if="currentCurrency.isLockable">: {{ unlockedAmount }} </h3>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h3 v-if="currentCurrency.isLockable">Locked Amount</h3>
                    </td>
                    <td>
                        <h3 v-if="currentCurrency.isLockable">: {{ lockedAmount }} </h3>
                    </td>
                </tr>
                -->
                <!--
                    <tr v-show="isLocked === false">
                        <td>
                            <h3>{{ $t('Transaction Fee') }}</h3>
                        </td>
                        <td>
                            <h3>
                                : 5%
                                {{ currentCurrency.fee }} {{ currentCurrency.symbol }}
                            </h3>
                        </td>
                    </tr>
                -->
                </table>
                <br />
            </div>

            <div class="btn-area" v-show="isLocked == true && currentCurrencyId != 'gkrw'">
                <router-link :to="`/${currentCurrencyId}/locked/send`">
                    <button class="button positive font-size-positive">{{ $t('Send') }}</button>
                </router-link>
                <router-link :to="`/${currentCurrencyId}/locked/receive`">
                    <button
                        style="background: #0c0f14; color: white; border: 1px solid white"
                        class="button positive font-size-positive"
                    >
                        {{ $t('Receive') }}
                    </button>
                </router-link>
            </div>

        <div class="btn-area" v-show="isLocked != true && currentCurrencyId != 'gkrw' && currentCurrencyId != 'fnb'">
            <router-link :to="`/${currentCurrencyId}/send`" v-show="currentCurrencyId != 'xusd'">
                <button class="button positive font-size-positive">{{ $t('Send') }}</button>
            </router-link>
            <router-link :to="`/${currentCurrencyId}/receive`">
                <button class="secondary button positive font-size-positive">{{ $t('Receive') }}</button>
            </router-link>
        </div>

            <template>
                <div style="font-size: 12px" class="balance-padding-lr setting-time-buttons">
                    <!-- 기간 버튼 비활성화 (삭제 예정)
			<table>
                <tr>
                    <td>
                        <button class="button" @click="settingSomething('1m', '60m')">1H</button>
                    </td>
                    <td>
                        <button class="button" @click="settingSomething('1h', '24h')">1D</button>
                    </td>
                    <td>
                        <button class="button" @click="settingSomething('1d', '7d')">1W</button>
                    </td>
                    <td>
                        <button class="button" @click="settingSomething('3d', '30d')">1M</button>
                    </td>
                    <td>
                        <button class="button" @click="settingSomething('1w', '12w')">3M</button>
                    </td>
                    <td>
                        <button class="button" @click="settingSomething('1M', '12M')">1Y</button>
                    </td>
                </tr>
            </table>
             -->
                </div>
            </template>

            <h2 class="transaction-history-title balance-padding-lr">
                {{ $t('Transaction History') }}
            </h2>
            <div class="balance-padding-lr unemphasis" v-if="!transactions">
                {{ $t('Loading transaction history...') }}
            </div>
            <section
                class="transaction-list balance-padding-lr"
                v-else-if="transactions.length >= 1"
            >
                <div
                    class="transaction"
                    v-for="transaction in transactions"
                    :key="transaction.txid"
                    @click="openExplorer(transaction.txid)"
                >
                    <div class="txn-left">
                        <div class="txn-ico-area">
                            <img
                                alt="receive"
                                v-if="transaction.type === 'in'"
                                src="../assets/ico/transaction/txn-receive.svg"
                            />
                            <img
                                alt="send"
                                v-else-if="transaction.type === 'out'"
                                src="../assets/ico/transaction/txn-send.svg"
                            />
                            <img alt="fee" v-else src="../assets/ico/transaction/txn-fee.svg" />
                        </div>
                        <div class="txn-info-area">
                            <div style="word-break: break-all" class="txn-info-txid">
                                {{
                                    transaction.isVerified
                                        ? transaction.txid
                                        : $t('Waiting for Admin Checking...')
                                }}
                            </div>
                            <div class="txn-info-date">
                                {{ getDateFormatted(transaction.timestamp) }}
                            </div>
                        </div>
                    </div>

                    <div class="txn-right">
                        <span class="txn-amount">{{
                            calcDisplayAmount(transaction.value, currentCurrency.fractionDigits)
                        }}</span>
                        <span class="txn-unit">{{ getCurrencyUnit(transaction) }}</span>
                        <template v-if="transaction.fee">
                            <br />
                            <span class="txn-amount"
                                >Fee :
                                {{
                                    calcDisplayAmount(
                                        transaction.fee,
                                        currencies[`${keyCurrencyFee}`].fractionDigits + 4
                                    )
                                }}</span
                            >
                            <span class="txn-unit">{{ keyCurrencyFee.toLocaleUpperCase() }}</span>
                        </template>
                    </div>
                </div>
            </section>
            <div class="balance-padding-lr unemphasis" v-else>
                {{ $t('Transaction history is empty') }}
            </div>
        </div>
    
</template>

<script>
import Appbar from '../components/Appbar';
import CurrencyPage from '../mixins/CurrencyPage';

import httpClient, { API_HOST } from '../httpClient';

import { mapGetters } from 'vuex';
import { toFixedNumberAndLocaleString } from '../components/utils';

export default {
    mixins: [CurrencyPage],
    components: {
        Appbar,
    },
    data() {
        return {
            transactions: [],
            datacollection: [],
            interval: '1h',
            duration: '24h',

            options: {
                animation: {
                    duration: 1, // disable animation
                },
                legend: {
                    //hides the legend
                    display: false,
                },
                scales: {
                    //hides the y axis
                    yAxes: [
                        {
                            display: false,
                            ticks: {
                                fontSize: 15,
                                maxTicksLimit: 1,
                                fontColor: 'white',
                                callback: function (value) {
                                    return '$' + toFixedNumberAndLocaleString(value, 2);
                                },
                            },
                            gridLines: {
                                color: 'rgb(95,169,85)',
                            },
                        },
                    ],
                    xAxes: [
                        {
                            display: false,
                        },
                    ],
                },
            },
            graphRange: {
                min: null,
                max: null,
            },
        };
    },
    computed: {
        ...mapGetters(['currencies', 'rates']),
        lockedAmount: {
            get: function () {
                return this.currentCurrency.locked;
            },
        },
        unlockedAmount: {
            get: function () {
                let unlockedAmount = this.currentCurrency.balance - this.lockedAmount;

                if (unlockedAmount < 0) {
                    unlockedAmount = 0;
                }

                return unlockedAmount;
            },
        },
        balanceAmountForDisplay: {
            get: function () {
                return toFixedNumberAndLocaleString(
                    this.currentCurrency.balance,
                    this.currentCurrency.fractionDigits
                );
            },
        },
        balancePriceForDisplay: {
            get: function () {
                return toFixedNumberAndLocaleString(
                    this.currentCurrency.balance * this.rates[`${this.currentCurrencyId}`],
                    2
                );
            },
        },
        keyCurrencyFee: {
            get: function () {
                let currencyFeeUnit = '';

                if (this.currentCurrency.reliesOnTron) {
                    currencyFeeUnit = 'eth';
                } else {
                    currencyFeeUnit = this.currentCurrencyId;
                }

                return currencyFeeUnit;
            },
        },
    },
    mounted() {
        //if ( ! Object.keys(this.currencies).includes(this.currentCurrencyId)) {
        if (!this.currentCurrency) {
            // not recognized currency; go to home;
            this.$router.replace('/');

            return false;
        }

        this.filldata();
        this.fetchTxHistory();
    },
    methods: {
        async fetchTxHistory() {
            const { data } = await httpClient.get(
                API_HOST(`/api/${this.currentCurrencyId}/history`)
            );
            console.log(data);
            data.sort(function (a, b) {
                return a.timestamp - b.timestamp;
            });
            this.transactions = data;
        },
        openExplorer(txid) {
            if (this.currentCurrencyId === 'eth') {
                this.openETHExplorer(txid);
            } else if(this.currentCurrencyId === 'btc') {
                this.opeBTCExplorer(txid);
            } else {
                this.openETHExplorer(txid);
            }
        },
        openETHExplorer(txid) {
            window.open(`https://etherscan.io/address/${txid}`, '_self ', 'location=yes');
        },
        openSTXExplorer(txid) {
            window.open(
                `https://explorer.hiro.so/txid/${txid}?chain=mainnet`,
                '_self ',
                'location=yes'
            );
        },
        opeBTCExplorer(txid) {
            window.open(
                `https://www.blockchain.com/explorer/transactions/btc/${txid}`,
                '_self ',
                'location=yes'
            );
        },
        settingSomething(interval, duration) {
            this.duration = duration;
            this.interval = interval;
            console.log(`${interval}, ${duration}`);
            this.filldata();
        },
        async filldata() {
            try {
                const { data } = await httpClient.get(
                    API_HOST(`/api/system/rate/${this.currentCurrencyId}/${this.interval}`)
                );

                let minVal = 99999999;
                let maxVal = -99999999;
                data.map((val) => {
                    val = Number(val);
                    if (val < minVal) {
                        minVal = val;
                    }
                    if (val > maxVal) {
                        maxVal = val;
                    }
                });

                this.graphRange = {
                    min: minVal,
                    max: maxVal,
                };

                // value changes not reflected on LineChart.js
                // this.options.scales.yAxes[0].ticks.min = minVal;
                // this.options.scales.yAxes[0].ticks.max = maxVal;

                this.datacollection = {
                    labels: [...new Array(data.length)],
                    datasets: [
                        {
                            type: 'line',
                            lineTension: 0.3,
                            backgroundColor: 'transparent',
                            borderColor: 'rgb(95,169,85)',
                            data: data,
                            pointRadius: 0,
                        },
                    ],
                };
            } catch (e) {
                this.datacollection = null;
            }
        },

        calcDisplayAmount(amount, fractionDigits) {
            return toFixedNumberAndLocaleString(amount, fractionDigits);
        },

        getDateFormatted(dateStr) {
            try {
                let moment = this.$moment(dateStr);
                return moment.format('YYYY-MM-DD HH:mm:ss');
            } catch (e) {
                // do nothing
            }

            // cannot process anything: return raw;
            return dateStr;
        },

        getCurrencyUnit(txn) {
            let currencyId = '';

            if (txn.type === 'in' || txn.type === 'out') {
                currencyId = this.currentCurrencyId;
            } else if (this.currentCurrency.reliesOnTron) {
                currencyId = 'eth';
            } else {
                currencyId = this.currentCurrencyId;
            }

            return currencyId.toLocaleUpperCase();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../const';

header:not(.app-bar) {
    position: relative;

    pointer-events: none;
    div.balance-area {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }
}

.balance-padding-lr {
    padding-left: 16px;
    padding-right: 16px;

    font-size: 16px;
    font-weight: bold;
}

.cryptocurrency-list {
    padding: 16px;
    .cryptocurrency {
        align-items: start;
        div {
            flex: 1;
        }
        .info-container {
            display: flex;
            gap: 8px;
            align-items: start;
        }
        .graph-container {
            align-self: end;
        }
    }
}

.setting-time-buttons table tr td button {
    background-color: $BASE_COLOR;
    border: 1px solid $BORDER_COLOR;
    color: #acacac;

    &:focus {
        border: 1px solid $BASE_SUB_COLOR_BRIGHTER;
        color: $BASE_SUB_COLOR_BRIGHTER;
    }
}

.btn-area {
    display: flex;
    padding: 0 16px;
    margin-bottom: 30px;
    & > * {
        flex: 1;
        &:first-child {
            margin-right: 8px;
        }
        &:last-child {
            margin-left: 8px;
        }
        button {
            width: 100%;
        }
    }
}

.transaction-history-title {
    margin-top: 35px;
    text-align: center;
    color: $BASE_SUB_COLOR;
    font-size: 18px;
    font-weight: 900;
}

.transaction-list {
    // padding: 8px;

    .transaction {
        // each transaction history
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        box-sizing: border-box;

        * {
            box-sizing: border-box;
        }

        .txn-left {
            display: flex;
            flex-direction: row;
            align-items: center;
            max-width: 60%;

            .txn-ico-area {
                // only 1 icon
                margin-right: 15px;

                img {
                    display: block;
                    width: 30px;
                    height: 30px;
                }
            }

            .txn-info-area {
                // refer .txn-ico-area
                max-width: calc(100% - (30px + 15px));

                display: flex;
                flex-direction: column;
                justify-content: left;

                font-size: 14px;

                .txn-info-txid {
                    max-width: 100%;

                    font-family: monospace;

                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .txn-right {
            //amount and unit

            max-width: 39%;
            font-size: 14px;

            text-align: right;

            .txn-amount {
                font-weight: bold;
            }
            .txn-unit {
                opacity: 0.6;
            }
        }
    }
}

.unemphasis {
    opacity: 0.6;
    margin-top: 10px;
}

.chart-area {
    padding: 0 16px;
    margin-bottom: 15px;

    canvas {
        max-height: 180px !important;
        width: calc(100% - (16px * 1)) !important;
    }
}

.direction-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
}

.min-value-area {
    margin-top: 8px;
}
</style>
