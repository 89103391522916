<template>
    
        <div class="confirm-send">
            <Appbar can-navigate-back title="Confirm" />
            <div class="page-padding-lr pageRoot">
                <div class="number-entity">
                    <label>{{ $t('Amount to send') }}</label>
                    <div class="number-area">
                        <span class="number">{{ amount }}</span>
                        <span class="unit">{{ currentCurrencyId.toLocaleUpperCase() }}</span>
                    </div>
                </div>

                <div class="number-entity">
                    <label>{{ $t('Tx Fee') }}</label>
                    <span>{{ $t('Tx Fee') }} : </span>
                    <div class="number-area">
                        <span class="number">{{ currentCurrency.fee }}</span>
                        <span class="unit">{{ currentFeeUnit }}</span>
                    </div>
                </div>

                <div class="number-entity">
                    <label>{{ $t('From Address') }}</label>
                    <code>{{ currentCurrency.address }}</code>
                </div>
                <div class="number-entity">
                    <label>{{ $t('To Address') }}</label>
                    <code>{{ toAddress }}</code>
                </div>
                <div>
                    <div class="confirmMessage">
                        {{ $t('Are you sure you want to proceed with this?') }}
                    </div>
                    <button
                        :disabled="isProcessing"
                        @click="confirmSend"
                        class="button positive font-size-positive"
                    >
                        {{ isProcessing ? $t('Processing...') : $t('Yes') }}
                    </button>
                </div>
            </div>
        </div>
    
</template>

<script>
import Appbar from '../components/Appbar';
import CurrencyPage from '../mixins/CurrencyPage';
import httpClient, { API_HOST } from '../httpClient';

export default {
    name: 'ConfirmSend',
    mixins: [CurrencyPage],
    components: { Appbar },

    computed: {
        toAddress() {
            return this.$route.params['address'];
        },
        amount() {
            return this.$route.params['amount'];
        },
        otp() {
            return this.$route.params['otp'];
        },
        currentFeeUnit() {
            if (this.currentCurrency.reliesOnTron) {
                return 'eth'.toLocaleUpperCase();
            }
            return this.currentCurrencyId.toLocaleUpperCase();
        },
    },

    data() {
        return {
            isProcessing: false,
        };
    },

    mounted() {
        if (!this.toAddress || (!this.amount && this.amount !== 0)) {
            alert(
                `${this.$t('you can send')} ${this.currentCurrencyId.toLocaleUpperCase()}${this.$t('after input both address and amount from \'Send\' screen.')}`
            );
            if (
                this.currentCurrencyId &&
                typeof this.currentCurrencyId === 'string' &&
                this.currentCurrencyId.trim()
            ) {
                this.$router.replace(`/${this.currentCurrencyId}/balance`);
            } else {
                this.$router.replace('/');
            }

            return false;
        }
    },

    methods: {
        async confirmSend() {
            if (this.isProcessing) {
                alert(`${this.$t('Transaction in progress... Please wait')}`);
                return false;
            }
            this.isProcessing = true;

            let successful = false;
            let excMsg = '';
            try {
                const endpoint = `/api/${this.currentCurrencyId}/send`;
                const { data } = await httpClient.post(API_HOST(endpoint), {
                    amount: this.amount,
                    to_addr: this.toAddress,
                    otp: this.otp,
                });

                if (data.success) {
                    successful = true;
                }
            } catch (e) {
                console.error(e);
                excMsg = e.message;
            } finally {
                if (successful) {
                    this.$router.replace({
                        name: 'completeSend',
                        params: {
                            currency: this.currentCurrencyId,
                            address: this.toAddress,
                            amount: this.amount,
                        },
                    });
                } else {
                    alert(`${this.$t('transaction request has failed.')} ${excMsg}`);

                    this.$router.replace('/');
                }

                this.isProcessing = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.confirm-send {
    & > .pageRoot {
        margin-top: 42px;
    }

    .block-entity {
        code {
            word-break: break-all;
            font-size: 16px;
        }
    }

    .confirmMessage {
        font-size: 16px;
        line-height: 1.25;
        text-align: center;

        margin-top: 31px;
        margin-bottom: 64px;
    }
}
</style>
