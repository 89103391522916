<template>
    
        <div class="balance">
            <Appbar title="Coin Swap" />
            <div class="cryptocurrency-list">
                <div class="cryptocurrency">
                    <div>
                        <div class="currency-name">{{$t('Pay')}}</div>
                        <div class="input-entity">
                            <label
                                >{{$t('Amount of USDT ( You Have :')}}
                                {{
                                    Math.floor(this.currencies['usdt'].balance * 1000000) / 1000000
                                }}
                                USDT )</label
                            >
                            <div class="btnArea text-align-right">
                                <input
                                    class="black"
                                    style="width: 70%"
                                    type="number"
                                    v-model="amount"
                                    min="0"
                                    :placeholder="`${$t('Enter amount to pay')}`"
                                />
                                <button
                                    style="
                                        border-radius: 8px;
                                        background-color: #0c0f14;
                                        border: 1px solid #ffdd00;
                                    "
                                    class="button mini"
                                    @click="putAmountToMaximum"
                                >
                                    {{$t('Max')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="cryptocurrency-list">
                <div class="cryptocurrency">
                    <div>
                        <div class="currency-name">{{$t('Receive')}}</div>
                        <div class="input-entity">
                            <label>{{$t('Amount of XUSD (XUSD)')}}</label>
                            <input
                                class="black"
                                type="number"
                                v-model="receive"
                                min="0"
                                :placeholder="`${$t('Enter amount to receive')}`"
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </div>

            <button style="font-size: 14px" class="button positive" @click="swap">   {{$t("Swap")}}</button>

            <span>
                <!-- ※ You can trade {{ 0.05 }} ETH in every 24 hours. -->
            </span>
            <!--
        <h2 class="transaction-history-title balance-padding-lr">Trade History</h2>
        <div class="balance-padding-lr unemphasis" v-if="!transactions">
            Loading trade history...
        </div>
        <section class="transaction-list balance-padding-lr" v-else-if="transactions.length >= 1">
            <div class="transaction" v-for="transaction in transactions" :key="transaction.txid" @click="openExplorer(transaction.txid)">
                <div class="txn-left">
                    <div class="txn-ico-area">
                        <img alt="receive" v-if="transaction.type === 'in'" src="../assets/ico/transaction/txn-receive.svg" />
                        <img alt="send" v-else-if="transaction.type === 'out'" src="../assets/ico/transaction/txn-send.svg" />
                        <img alt="fee" v-else src="../assets/ico/transaction/txn-fee.svg" />
                    </div>
                    <div class="txn-info-area">
                        <div style="word-break:break-all;" class="txn-info-txid">{{ transaction.isVerified ? transaction.txid : "Waiting for Admin Checking..." }}</div>
                        <div class="txn-info-date">{{ getDateFormatted(transaction.timestamp) }}</div>
                    </div>
                </div>

                <div class="txn-right">
                    <span class="txn-amount">{{ calcDisplayAmount(transaction.value, currentCurrency.fractionDigits) }}</span> <span class="txn-unit">{{ getCurrencyUnit(transaction) }}</span>
                    <template v-if="transaction.fee">
                        <br />
                        <span class="txn-amount">Fee : {{ calcDisplayAmount(transaction.fee, currencies[`${keyCurrencyFee}`].fractionDigits + 4) }}</span> <span class="txn-unit">{{ keyCurrencyFee.toLocaleUpperCase() }}</span>
                    </template>
                </div>
            </div>
        </section>
        <div class="balance-padding-lr unemphasis" v-else>
            Trade history is empty
        </div>
        -->
            <FooterNavbar />
        </div>
    
</template>

<script>
import Appbar from '../components/Appbar';
import FooterNavbar from '../components/FooterNavbar';
import CurrencyPage from '../mixins/CurrencyPage';
// import LineChart from '../components/LineChart.js'

import httpClient, { API_HOST } from '../httpClient';

import { mapGetters } from 'vuex';

export default {
    mixins: [CurrencyPage],
    components: {
        Appbar,
        FooterNavbar,
        // LineChart,
    },
    data() {
        return {
            receive: 0,
            amount: 0,
            daily_limit: 0.05,
            eth_price: 0,
        };
    },
    computed: {
        ...mapGetters(['currencies', 'rates']),
    },
    async mounted() {
    },
    methods: {
        async swap() {
            if (this.amount < 0) {
                alert(`${this.$i18n.t('Swap Failed')}`);
                return;
            }
            const { data } = await httpClient.post(API_HOST('/__authorize/swap'), {
                amount: this.amount,
            });

            if(data.success == true){
                alert(this.$i18n.t("Swap Success"));
                this.amount = 0;
                this.receive = 0;
            } else {
                alert(this.$i18n.t('Swap Failed')+`\nReason :${data.message}`);
            }
        },
        async putAmountToMaximum() {
            const eth_bal = (parseFloat(this.currencies['xusd'].balance) * 1 / 1);
            const eth_price = (parseFloat(this.currencies['xusd'].price) * 1 / 1);
            this.eth_price = eth_price;

            if (this.daily_limit < eth_bal * eth_price) {
                this.amount = this.daily_limit;
                //    this.amount = Math.floor(this.daily_limit / eth_price * 0.999 * 10 ** 4) / 10 ** 4;
                //} else if(eth_bal * eth_price > 100) {
                //    this.amount = 100 / eth_price;
            } else {
                this.amount = eth_bal;
            }
        },
    },
    watch: {
        amount : function(val){
            this.receive = (Math.floor((val * this.currencies['usdt'].price / this.currencies['xusd'].price) * 10 ** 2) / 10 ** 2)?.toFixed(this.currencies['xusd'].fractionDigits);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../const';

header:not(.app-bar) {
    position: relative;

    pointer-events: none;
    div.balance-area {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }
}

.balance-padding-lr {
    padding-left: 16px;
    padding-right: 16px;

    font-size: 16px;
    font-weight: bold;
}

.cryptocurrency-list {
    padding: 16px;
    .cryptocurrency {
        align-items: start;
        div {
            flex: 1;
        }
        .info-container {
            display: flex;
            gap: 8px;
            align-items: start;
        }
        .graph-container {
            align-self: end;
        }
    }
}

.setting-time-buttons table tr td button {
    background-color: $BASE_COLOR;
    border: 1px solid $BORDER_COLOR;
    color: #acacac;

    &:focus {
        border: 1px solid $BASE_SUB_COLOR_BRIGHTER;
        color: $BASE_SUB_COLOR_BRIGHTER;
    }
}

.btn-area {
    display: flex;
    padding: 0 16px;
    margin-bottom: 30px;
    & > * {
        flex: 1;
        &:first-child {
            margin-right: 8px;
        }
        &:last-child {
            margin-left: 8px;
        }
        button {
            width: 100%;
        }
    }
}

.transaction-history-title {
    margin-top: 35px;
    text-align: center;
    color: $BASE_SUB_COLOR;
    font-size: 18px;
    font-weight: 900;
}

.transaction-list {
    // padding: 8px;

    .transaction {
        // each transaction history
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        box-sizing: border-box;

        * {
            box-sizing: border-box;
        }

        .txn-left {
            display: flex;
            flex-direction: row;
            align-items: center;
            max-width: 60%;

            .txn-ico-area {
                // only 1 icon
                margin-right: 15px;

                img {
                    display: block;
                    width: 30px;
                    height: 30px;
                }
            }

            .txn-info-area {
                // refer .txn-ico-area
                max-width: calc(100% - (30px + 15px));

                display: flex;
                flex-direction: column;
                justify-content: left;

                font-size: 14px;

                .txn-info-txid {
                    max-width: 100%;

                    font-family: monospace;

                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .txn-right {
            //amount and unit

            max-width: 39%;
            font-size: 14px;

            text-align: right;

            .txn-amount {
                font-weight: bold;
            }
            .txn-unit {
                opacity: 0.6;
            }
        }
    }
}

.unemphasis {
    opacity: 0.6;
    margin-top: 10px;
}

.chart-area {
    padding: 0 16px;
    margin-bottom: 15px;

    canvas {
        max-height: 180px !important;
        width: calc(100% - (16px * 1)) !important;
    }
}

.direction-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
}

.min-value-area {
    margin-top: 8px;
}

div.input-entity {
    display: flex;
    flex-direction: column;

    padding: 0 16px;
    margin-bottom: 20px;

    label {
        font-size: 14px;
        line-height: 1.33;
        font-weight: bold;
        color: $TEXT_COLOR;

        margin-bottom: 6px;
    }

    input {
        border: 0;

        height: 56px;

        box-sizing: border-box;

        transition: all 0.3s;

        color: $BASE_COLOR;
        padding: 16px;
        font-size: 16px;
        line-height: 1.31;

        outline: 0;

        padding-right: 56px; //+16px;
        background-color: $BASE_COLOR;
        border: 1px solid $BORDER_COLOR;
        color: $TEXT_COLOR;
        &::placeholder,
        &::-webkit-input-placeholder {
            color: rgba($TEXT_COLOR, 0.87);
            opacity: 0.5;
        }

        &:focus {
            border-bottom: 2px solid $BASE_COLOR;
        }

        &::placeholder,
        &::-webkit-input-placeholder {
            color: rgba($BASE_COLOR, 0.87);
            opacity: 0.5;
        }
        &.white {
            background-color: white;
            border-bottom: none;
        }
        &.black {
            background-color: $BASE_BACK_COLOR;
            border: 1px solid $BORDER_COLOR;
            color: white;
            &::placeholder,
            &::-webkit-input-placeholder {
                color: rgba($TEXT_COLOR, 0.87);
                opacity: 0.5;
            }
        }
    }

    &.input-email {
        input {
            width: 100%;
            background-color: $BASE_COLOR;
            border: 1px solid $BORDER_COLOR;
            color: $TEXT_COLOR;
            &::placeholder,
            &::-webkit-input-placeholder {
                color: rgba($TEXT_COLOR, 0.87);
                opacity: 0.5;
            }
        }
    }

    &.change-profile {
        padding-top: 30px;

        input.change-input {
            width: 100%;
            margin-bottom: 20px;
            background-color: $BASE_COLOR;
            border: 1px solid $BORDER_COLOR;
            color: $TEXT_COLOR;
            &::placeholder,
            &::-webkit-input-placeholder {
                color: rgba($TEXT_COLOR, 0.87);
                opacity: 0.5;
            }
        }

        label {
            font-size: 12px;
            line-height: 1.33;
            font-weight: bold;
            color: $TEXT_COLOR;
            display: block;
            margin-bottom: 4px;
        }
    }

    &.watchable-password {
        div.input-area {
            position: relative;

            input.input-password {
                width: 100%;
                padding-right: 56px; //+16px;
                background-color: $BASE_COLOR;
                border: 1px solid $BORDER_COLOR;
                color: $TEXT_COLOR;
                &::placeholder,
                &::-webkit-input-placeholder {
                    color: rgba($TEXT_COLOR, 0.87);
                    opacity: 0.5;
                }
            }

            button.open-input-password {
                position: absolute;
                right: 0;
                top: 0;

                background: transparent;
                box-shadow: none;

                width: 56px;
                height: 56px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    &.scannable-qr-code {
        div.input-area {
            position: relative;

            input.input-address {
                width: 100%;
                padding-right: 56px; //+16px;
            }
            button.scan-qr-code {
                position: absolute;
                right: 0;
                top: 0;

                background: transparent;
                box-shadow: none;

                width: 56px;
                height: 56px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
</style>
