import Vue from 'vue';
import VueMoment from 'vue-moment';

import App from './App.vue';
import router from './router';
import store from './store';
import { i18n } from './locales/i18n';
//import VueI18n from 'vue-i18n'


Vue.use(VueMoment);

Vue.config.productionTip = false;
//Vue.use(VueI18n)
new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
}).$mount('#mounted-app');
